import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2eabe102"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "btn_box" }

import { ElMessage } from "element-plus"
import { ref } from "vue"
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

export default {
  props: {
  imgName:String,
  imgLength:Number,
},
  setup(__props) {

const props = __props


let currentPage = ref(1)
const prev = ()=>{
  if(currentPage.value>1){
    currentPage.value--
    scrollIntoView(document.querySelector('body'),{ 
      scrollMode: 'if-needed',
      block: 'nearest',
      inline: 'nearest',
    });
  }
  else ElMessage.warning({message:'当前已经是第一页了喔'})
}
const next = ()=>{
  if(currentPage.value<props.imgLength){
    currentPage.value++
    scrollIntoView(document.querySelector('body'),{ 
      scrollMode: 'if-needed',
      block: 'nearest',
      inline: 'nearest',
    });
  }else ElMessage.warning({message:'当前已经是最后一页了喔'})
}

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      lazy: "",
      src: require('@/assets/help/'+__props.imgName+_unref(currentPage)+'.jpg'),
      alt: ""
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["prev flex_r", _unref(currentPage)==1?'no':'']),
        onClick: prev
      }, "上一页", 2),
      _createElementVNode("div", {
        class: _normalizeClass(["next flex_r", _unref(currentPage)==__props.imgLength?'no':'']),
        onClick: next
      }, "下一页", 2)
    ])
  ], 64))
}
}

}